/* Create Sticker Modal Image Styles */
.sticker-create-container {
  position: relative;
  overflow: hidden;
}

.sticker-create-container .sticker-create-delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.sticker-create-container:hover .sticker-create-delete-overlay {
  opacity: 1;
}

.sticker-create-container img {
  transition: filter 0.3s ease;
}

.sticker-create-container:hover img {
  filter: brightness(0.7);
}

/* Updated Sticker List Styles */
.sticker-list-item {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease;
}

.sticker-list-item:hover {
  transform: translateY(-2px);
}

.sticker-list-image-wrapper {
  width: 100%;
  padding-top: 75%; /* 4:3 Aspect Ratio */
  position: relative;
}

.sticker-list-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f8f9fa; /* Light background for empty space */
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticker-list-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* This will maintain aspect ratio */
  padding: 8px; /* Add some padding around the image */
}

.sticker-list-delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.sticker-list-image-container:hover .sticker-list-delete-overlay {
  opacity: 1;
}

.sticker-list-content {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sticker-title {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.sticker-value {
  margin-bottom: 0;
  color: #6c757d;
}