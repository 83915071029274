.package-card {
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.package-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
