.posts-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.post-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 12px;
  transition: box-shadow 0.3s ease;
}

.post-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 12px 24px rgba(0, 0, 0, 0.12);
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.post-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.post-date {
  margin: 0;
  font-size: 12px;
  color: #65676b;
}

.post-content {
  margin-bottom: 12px;
}

.post-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 8px;
  margin-bottom: 12px;
}

.carousel-image-container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.carousel-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.single-image-container {
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
}

.post-footer {
  border-top: 1px solid #e4e6eb;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reactions-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f2f5;
  padding: 4px 10px;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.reaction-emojis {
  display: flex;
  align-items: center;
  gap: 2px;
}

.reaction-emoji {
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  object-fit: cover;
}

.reaction-emoji:hover {
  transform: scale(1.1);
}

.reaction-count {
  font-size: 14px;
  font-weight: 600;
  color: #444;
  background-color: white;
  padding: 2px 8px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.post-stats {
  color: #65676b;
  font-size: 14px;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #65676b;
}

.error-message {
  color: #ff4444;
  text-align: center;
  padding: 20px;
}

.comments-container {
  max-height: 500px;
  overflow-y: auto;
}

.comment-item {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.comment-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.comment-user-info h6 {
  margin: 0;
  font-size: 14px;
}

.comment-user-info small {
  color: #65676b;
}

.comment-content {
  margin: 0.5rem 0;
}

.comment-content p {
  margin-bottom: 0.5rem;
}

.comment-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.comment-attached-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.comment-attached-image:hover {
  transform: scale(1.02);
}

.view-replies-btn {
  background: transparent;
  border: none;
  color: #1976d2;
  font-size: 0.9rem;
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 12px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.view-replies-btn:hover {
  background: rgba(25, 118, 210, 0.08);
  color: #1565c0;
}

.view-replies-btn:active {
  background: rgba(25, 118, 210, 0.12);
}

.view-replies-btn i {
  font-size: 0.8rem;
  transform: scaleX(-1);
}

.replies-section {
  margin-left: 2rem;
  margin-top: 0.5rem;
  border-left: 2px solid #e0e0e0;
  padding-left: 1rem;
}

.reply-item {
  background-color: #f8f9fa;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.reply-content {
  margin-top: 0.5rem;
  font-size: 0.95rem;
}

.replies-loading {
  margin-left: 2rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.comments-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: #f0f2f5;
  color: #65676b;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.comments-button:hover {
  background-color: #e4e6e9;
}

.comments-button i {
  font-size: 16px;
}
